@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

@keyframes underline-enter {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

.homepage-heading-container {
    width: 100%;
    background-color: transparent;

    @include media-breakpoint-up(md) {
        max-width: 520px;
    }

    .homepage-heading-wrapper {
        gap: 16px;
    }

    .homepage-heading-eyebrow {
        color: $neutral-70;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            color: $neutral-80;
            font-size: 18px;
            line-height: 26px;
        }
    }

    .homepage-heading-title {
        color: $dark-green;
        font-family: $font-qualion;
        font-size: var(--headingFontSizeMobile);
        font-weight: var(--headingFontWeightMobile);
        line-height: 36px;

        &.underline-animation {
            .underline {
                &::after {
                    animation: underline-enter 2s 1;
                }
            }
        }

        @include media-breakpoint-up(md) {
            font-size: var(--headingFontSizeDesktop);
            font-weight: var(--headingFontWeightDesktop);
            line-height: 48px;
        }

        .underline {
            z-index: 1;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 14px;
                transform: rotate(-2deg);
                left: 0;
                bottom: 0;
                background: $gold;
                z-index: -1;
            }
        }
    }

    .homepage-heading-subtitle {
        color: $dark-green;
        font-family: $font-qualion;
        color: $dark-green;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 26px;
        }
    }
}